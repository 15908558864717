import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import App from './App';
import './index.css';
import './indexPaylink.css';
import './polyfills';
import * as serviceWorker from './serviceWorker';

if (import.meta.env.REACT_APP_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://e6e16a74d40d4bf9a5aa25d1eb465c36@o79414.ingest.sentry.io/5630811',
    environment: 'production',
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/
    integrations: [
      Sentry.browserTracingIntegration(), // Capture performance data for the Browser.
      // Capture console.error()
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      // Extracts all non-native attributes from the error object and attaches them to the event as extra data.
      Sentry.extraErrorDataIntegration(),
      // Captures errors on failed requests from Fetch and XHR and attaches request and response information.
      Sentry.httpClientIntegration(),
      // Capture a video-like reproduction of what was happening in the user's browser. Use class .sentry-mask to mask the data
      Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
    ],

    tracesSampleRate: 0.2, // 20%
    replaysSessionSampleRate: 0.001, // 0.1%
    replaysOnErrorSampleRate: 0.1, // 10%

    // This option is required for capturing headers and cookies. To HttpClient()
    sendDefaultPii: true,
    // includeLocalVariables: true, // Impossible on browser-js: https://github.com/getsentry/sentry-javascript/issues/990#issuecomment-317048074
    ignoreErrors: [
      'ConnectionError',
      'TypeError: Failed to fetch', // Sentry error Sentry: REACT-301
      'TypeError: Load failed', // iPhone version of Failed to fetch
      'Error: JSONP request failed', // Livechat error Sentry: REACT-20M
      'Unable to preload CSS', // Fails possibly due to a timeout, it happens more on IOS because the time is shorter there
    ],
  });
}

const container: HTMLElement = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregisterServiceWorker();
