import React, { useEffect, useState } from 'react';
import iconConectionError from '../assets/error/connectionError.svg';
import iconHttpError from '../assets/error/error.svg';
import iconUnexpectedError from '../assets/error/unexpectedError.svg';
import { GetSessionId } from '../services/AuthService.ts';
import fetchCurrentUser from '../services/CurrentUser.ts';
import sendSentryError from '../services/SentryService';

export default function ErrorComponent({ error, textError = null }) {
  const [user, setUser] = useState({});

  const errorText = {
    400: 'Petición inválida',
    401: 'No autorizado',
    403: 'No autorizado',
    404: 'No encontrado',
    405: 'Método no permitido',
    500: 'Error del servidor',
    502: 'Bad Gateway',
    503: 'Servicio no disponible',
    504: 'Timeout, Recargue la página',
  };

  useEffect(() => {
    fetchCurrentUser().then((response) => setUser(response));
    const msgSentryError = `Error component: ${errorText[error.status]}` || '';
    sendSentryError(error, msgSentryError);
  }, []);

  if (error == null) {
    // Juuuust in case
    return <h1>ERROR NO DEFINIDO</h1>;
  }

  // eslint-disable-next-line no-param-reassign
  if (!textError) textError = errorText[error.status] || 'Error desconocido';

  if (error.status === 401 && !user) {
    window.location.href = `${import.meta.env.REACT_APP_API_SERVER}/login?next=/aplicar`;
  }

  const ShowRequestInfo = error.request && (
    <p style={{ wordBreak: 'break-all' }}>
      <strong>Request:</strong>
      {' '}
      {error.request.method}
      {' '}
      {error.request.url}
    </p>
  );
  if (error.name === 'HttpError') {
    return (
      <div className="container text-center mt-80">
        <h1>Oops!</h1>
        <h1>
          {error.status}
          -
          {textError}
        </h1>
        <div className="row text-left mt-30">
          <div className="col">
            {error.status === 400 && (
              <div className="container text-center mb-4">
                <button
                  className="btn btn-outline-success btn-lg"
                  onClick={() => window.location.reload()}
                  type="button"
                >
                  Reintentar
                </button>
              </div>
            )}
            {error.responseJson.bodyJson.detail && (
              <p>
                <strong>Detalle: </strong>
                {error.responseJson.bodyJson.detail}
              </p>
            )}
            <p>
              <strong>User Id: </strong>
              {user.id}
            </p>
            <p>
              <strong>Usuario: </strong>
              {user.first_name}
              {user.last_name}
              -
              {user.email}
            </p>

            {ShowRequestInfo}

            <p>
              <strong>SessionId: </strong>
              {GetSessionId()}
            </p>
            <p>
              <strong>Navegador: </strong>
              {navigator.userAgent}
            </p>
          </div>
        </div>
        <img
          className="img-fluid mw-500"
          src={iconHttpError}
          alt="Error svg"
        />
      </div>
    );
  } if (error.name === 'ConnectionError') {
    return (
      <div className="container text-center mt-80">
        <h1>Oops!</h1>
        <h1>Error de conexión</h1>
        <p>Revisa tu conexión a intenet y recarga la página</p>
        <img
          className="img-fluid mw-500"
          src={iconConectionError}
          alt="Error svg"
        />
      </div>
    );
  }
  // Includes "unhandledrejection"
  return (
    <div className="container text-center mt-80">
      <h1>
        Error Inesperado:
        <br />
        {error.name}
      </h1>
      <h5>{error.message}</h5>
      <div className="row text-left mt-30">
        <div className="col">
          <p>
            <strong>User Id: </strong>
            {user.id}
          </p>
          <p>
            <strong>Usuario: </strong>
            {user.first_name}
            {user.last_name}
            -
            {user.email}
          </p>

          {ShowRequestInfo}

          <p>
            <strong>URL: </strong>
            {window.location.href}
          </p>
          <p>
            <strong>SessionId: </strong>
            {GetSessionId()}
          </p>
          <p>
            <strong>Navegador: </strong>
            {navigator.userAgent}
          </p>
          {error.diagnosis_info && (
            <p>
              <strong>Info de diagnóstico: </strong>
              {error.diagnosis_info}
            </p>
          )}
        </div>
      </div>
      <img
        className="img-fluid mw-500"
        src={iconUnexpectedError}
        alt="Error svg"
      />
    </div>
  );
}
